import React, { useEffect, useState } from "react";

import { Card, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { editFaq } from "../../services/UserServices/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import { Editor } from "@tinymce/tinymce-react";

export default function EditFaqs() {
  let errorsObj = { question: "", answer: "", question_ch: "", answer_ch: "" };
  const location = useLocation();
  const categoryId = location.state.category;
  const title = location.state.title;
  const navigate = useNavigate();
  const data = location.state?.data;
  const faqId = location.state?.faqId;
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    question: "",
    question_ch: "",
    answer: "",
    answer_ch: "",
    faqId: "",
    questionId: "",
  });

  const [loader, setLoader] = useState(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleChangeText = (content) => {
    setFormData({ ...formData, answer: content });
    setErrors({ ...errors, answer: "" });
  };
  const handleChangeTextCh = (content) => {
    setFormData({ ...formData, answer_ch: content });
    setErrors({ ...errors, answer_ch: "" });
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.question.trim() === "") {
      errorObj.question = "This fiels is required";
      error = true;
    }
    if (formData?.answer.trim() === "") {
      errorObj.answer = "This fiels is required";
      error = true;
    }
    if (formData?.question_ch.trim() === "") {
      errorObj.question_ch = "This fiels is required";
      error = true;
    }
    if (formData?.answer_ch.trim() === "") {
      errorObj.answer_ch = "This fiels is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await editFaq(formData);
      notifyTopRight(response?.data?.message);
      setFormData({ question: "", answer: "", categoryId: "" });

      navigate("/faqs-list", {
        state: { id: categoryId, title: title },
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  useEffect(() => {
    setFormData({
      question: data?.question,
      answer: data?.answer,
      question_ch: data?.question_ch,
      answer_ch: data?.answer_ch,
      faqId: faqId,
      questionId: data?._id,
    });
  }, [data]);
  return (
    <Card>
      <form onSubmit={onSubmit}>
        <div className="card-header">
          <h4 className="card-title fs-20">Edit Details</h4>
        </div>
        <div className="card-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Question (English)</label>
            <input
              className="form-control"
              name="question"
              value={formData.question}
              onChange={handleInput}
            />
            {errors.question && (
              <div className="text-danger fs-12">{errors.question}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Question (Chinese)</label>
            <input
              className="form-control"
              name="question_ch"
              value={formData.question_ch}
              onChange={handleInput}
            />
            {errors.question_ch && (
              <div className="text-danger fs-12">{errors.question_ch}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="text-black font-w500">Answer (English)</label>
            <Editor
              apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image code charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code textcolor wordcount",
                  "textarea",
                  "textcolor",
                  "forecolor backcolor",
                ],
                toolbar:
                  "undo redo | formatselect | code |link | image | bold italic backcolor underline | alignleft aligncenter alignright alignjustify |  \n" +
                  "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                content_style: "body { color: #000 }",
              }}
              onEditorChange={handleChangeText}
              name="prescription"
              value={formData.answer}
            />
            {errors.answer && (
              <div className="text-danger fs-12">{errors.answer}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Answer (Chinese)</label>
            <Editor
              apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image code charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code textcolor wordcount",
                  "textarea",
                  "textcolor",
                  "forecolor backcolor",
                ],
                toolbar:
                  "undo redo | formatselect | code |link | image | bold italic backcolor underline | alignleft aligncenter alignright alignjustify |  \n" +
                  "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                content_style: "body { color: #000 }",
              }}
              onEditorChange={handleChangeTextCh}
              name="prescription"
              value={formData.answer_ch}
            />
            {errors.answer_ch && (
              <div className="text-danger fs-12">{errors.answer_ch}</div>
            )}
          </div>
        </div>
        <div className="card-footer text-end">
          <button type="submit" className="btn btn-primary me-2">
            Update
          </button>
          <button
            type="button"
            onClick={() =>
              navigate("/faqs-list", {
                state: { id: categoryId, title: title },
              })
            }
            className="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </form>
    </Card>
  );
}
