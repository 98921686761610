import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import { approveRejectRequest, payNow } from "../../../services/UserServices/UserService";

const PayNow = ({ show, id, table, onHide }) => {
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState({ amount: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const payload = {
            referralId: id,
          amount: amount,
        };
        await payNow(payload);
        notifyTopRight("Successfully");
        table();
        onHide();
        
      } catch (error) {
        notifyError(error.response?.data?.message || "An error occurred");
        console.error("Error:", error.response || error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errorObj = { amount: "" };

    if (!amount) {
      errorObj.amount = "This field is required";
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Pay Now</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Amount</label>
            <input
              type="number"
              className="form-control"
              value={amount}
              min={0}
              onChange={(e) => {
                setAmount(e.target.value);
                setErrors({ ...errors, amount: "" });
              }}
            />
            {errors.amount && (
              <div className="text-danger fs-12">{errors.amount}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PayNow;
