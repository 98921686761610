import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./admin/store/store";
import ThemeContext from "./admin/context/ThemeContext";
import { Toaster } from "react-hot-toast";
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="">
        <ThemeContext>
          <App />
          <Toaster
            reverseOrder={false}
            position="top-center"
            toastOptions={{
              style: {
                borderRadius: "8px",
                background: "#ffffff",
                color: "black",
              },
            }}
          ></Toaster>
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
