import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import { createCategory } from "../../../services/UserServices/UserService";

const AddFaqCategory = ({ show, table, onHide }) => {
  const [formData, setFormData] = useState({
    title: "",
    title_ch: "",
  });
  const [errors, setErrors] = useState({ title: "", title_ch: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await createCategory(formData);
        notifyTopRight("Created Successfully.");
        onHide();
        table();
      } catch (error) {
        notifyError(error.response?.data?.message || "An error occurred");
        console.error("Error:", error.response || error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errorObj = { title: "", title_ch: "" };
    if (!formData.title.trim()) {
      errorObj.title = "This field is required";
      isValid = false;
    }
    if (!formData.title_ch.trim()) {
      errorObj.title_ch = "This field is required";
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add Category</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">
              Category Name (English)
            </label>
            <input
              type="text"
              className="form-control"
              value={formData.title}
              onChange={(e) => {
                setFormData({ ...formData, title: e.target.value });
                setErrors({ ...errors, title: "" });
              }}
            />
            {errors.title && (
              <div className="text-danger fs-12">{errors.title}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">
              Category Name (Chinese)
            </label>
            <input
              type="text"
              className="form-control"
              value={formData.title_ch}
              onChange={(e) => {
                setFormData({ ...formData, title_ch: e.target.value });
                setErrors({ ...errors, title_ch: "" });
              }}
            />
            {errors.title_ch && (
              <div className="text-danger fs-12">{errors.title_ch}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button type="button" onClick={onHide} className="btn btn-secondary">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddFaqCategory;
