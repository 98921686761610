import React from "react";
import { Modal } from "react-bootstrap";

export default function FullImage({ show, image, onHide }) {
  return (
    <Modal
      className="modal fade image-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header className="border-0 w-100">
        <div className="image-container w-100" role="document">
          <img src={image} alt="img" className="w-100" />
        </div>
      </Modal.Header>
    </Modal>
  );
}
