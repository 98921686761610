import React, { useState } from "react";
import { createReferralCode } from "../../../services/UserServices/UserService";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import { Modal } from "react-bootstrap";

export default function ReferralCode({ show, table, onHide }) {
  let errorsObj = { code: "", name: "", expires: "" };

  const [errors, setErrors] = useState(errorsObj);

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    expires: "",
  });

  const [loader, setLoader] = useState(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.name.trim() === "") {
      errorObj.name = "Name is required";
      error = true;
    }
    if (formData?.code.trim() === "") {
      errorObj.code = "Code is required";
      error = true;
    }
    if (formData?.expires.trim() === "") {
      errorObj.expires = "Date is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await createReferralCode(formData);
      notifyTopRight(response?.data?.message);
      setFormData({ code: "" });
      onHide();
      table();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={onSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add Code</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Name</label>
            <input
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleInput}
            />
            {errors.name && (
              <div className="text-danger fs-12">{errors.name}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Referral Code</label>
            <input
              className="form-control"
              name="code"
              value={formData.code}
              onChange={handleInput}
            />
            {errors.code && (
              <div className="text-danger fs-12">{errors.code}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="text-black font-w500">Expires On</label>
            <input
              type="date"
              className="form-control"
              name="expires"
              // value={formData.expires}
              onChange={handleInput}
              min={new Date().toISOString().split("T")[0]}
            />
            {errors.expires && (
              <div className="text-danger fs-12">{errors.expires}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
}
