import React, { useEffect, useState, useRef } from "react";
import PageTitle from "../layouts/PageTitle";
import { Card, Col } from "react-bootstrap";
import Spinner from "../components/Common/Spinner";
import { changeFaqOrder, deleteFaq, getFaqsList } from "../../services/UserServices/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import parse from "html-react-parser";
import DraggableList from "react-draggable-list";

export default function FAQsList() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const limit = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [faqId, setFaqId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef();
  const [orderChanged, setOrderChanged] = useState(false);

  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getFaqsList(
        currentPage,
        limit,
        location?.state.id
      );
      const faqsData = response.data?.data?.faqs?.faqs || [];
      setFaqId(response.data?.data?.faqs?._id);
      setData(faqsData);
      setLoader(false);
      const total = response.data?.data?.count;
      setPageCount(Math.ceil(total / limit));
    } catch (error) {
      console.error("Error fetching FAQs:", error);
      setLoader(false);
    }
  };

  const handleDelete = async (id) => {
    setLoader(true);
    try {
      await deleteFaq(id,faqId);
      handleFetch();
      notifyTopRight("Deleted Successfully!");
      setLoader(false);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
      setLoader(false);
    }
  };

  const handleEdit = (item) => {
    navigate("/edit-faqs", {
      state: {
        data: item,
        category: location?.state.id,
        title: location?.state?.title,
        faqId:faqId
      },
    });
  };

  const _onListChange = async(newList) => {
    setData(newList);
    setOrderChanged(true);
  };
  const onSaveOrder = async () => {
    setLoader(true);
    var raw = {
      faqs: [
        ...data.map((item, i) => {
          return {
            faqId: item._id,
            order: i+1,
          };
        }),
      ],
    }

    console.log(raw,"raw")
    try {
      const response = await changeFaqOrder(
        raw
      );
      handleFetch();
      notifyTopRight("Updated Successfully!");

    } catch (error) {
      console.error("Error fetching FAQs:", error);
      setLoader(false);
    }
  };

  const Item = ({ item, dragHandleProps }) => {
    return (
      <div className="card mb-3" {...dragHandleProps} style={{border:"1px solid",zIndex:"1"}}>
        <div className="card-header d-flex justify-content-between align-items-center p-3 " style={{borderBottom:"1px solid"}}>
          <h6 className="mb-0">{item?.question}</h6>
          <div>
            <i
              className="flaticon-381-edit-1 mx-2 pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(item);
              }}
            ></i>
            <i
              className="flaticon-381-trash-1 mx-2 pe-3 pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(item?._id);
              }}
            ></i>
          </div>
        </div>
        <div className="card-body">
          {parse(item?.answer)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    handleFetch();
  }, [currentPage]);

  return (
    <div>
      <PageTitle activeMenu="FAQs List" motherMenu="FAQs" />
      <Col>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title>{location?.state?.title} FAQs</Card.Title>
            <div >
            {orderChanged && (
                <button
                  className="btn btn-secondary me-2 py-2"
                  onClick={() => {
                    onSaveOrder();
                  }}
                >
                  Save Change Order
                </button>
              )}
            <button
              type="button"
              className="btn btn-primary py-2"
              onClick={() =>
                navigate("/create-faqs", {
                  state: {
                    categoryId: location?.state.id,
                    title: location?.state?.title,
                  },
                })
              }
            >
              Add +
            </button>
            </div>
       
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <div ref={containerRef} className="draggable-list-container">
                <DraggableList
                  itemKey="_id"
                  template={Item}
                  list={data}
                  onMoveEnd={(newList) => _onListChange(newList)}
                  container={() => containerRef.current}
                  className="custom-draggable-list"
                  
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}