import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import {
    deletePost,
  getPostList,
  getWithdrawalRequests,
  withdrawalRequestAction,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import moment from "moment";

export default function PostManagement() {
  const [loader, setLoader] = useState(false);
  const [datas, setDatas] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  const navigate = useNavigate();

  async function getTableData() {
    setLoader(true);
    try {
      const response = await getPostList(currentPage, limit);
      console.log(response.data, "12365");
      setDatas(response.data.data?.posts);
      setLoader(false);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data.data?.count);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  }

  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deletePost(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <div>
      <PageTitle activeMenu="Post List" motherMenu="Post Management" />
      <Card>
        <Card.Body className="pb-0 pt-3">
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive className="mostly-customized-scrollbar mb-0">
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Posted By</strong>
                  </th>
                  <th>
                    <strong>Text</strong>
                  </th>
                  <th>
                    <strong>Post Date</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {datas &&
                  datas?.map((item, i) => (
                    <tr key={i}>
                      <td
                        onClick={() =>
                          navigate("/user-details", {
                            state: {
                              data: item?._id,
                              page: currentPage,
                            },
                          })
                        }
                        className="pointer"
                      >
                        {item?.user?.name}
                      </td>
                      <td style={{ maxWidth: "300px" }}>{item?.text}</td>
                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <button
                          onClick={() => onDelete(item._id)}
                          className="btn btn-danger py-1 px-2"
                        >
                          <i class="flaticon-381-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {datas?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {datas?.length !== 0 && (
          <div className="card-footer clearfix">
            <div className="d-block d-sm-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Showing {currentPage * limit + 1} to{" "}
                {listLength > (currentPage + 1) * limit
                  ? (currentPage + 1) * limit
                  : listLength}{" "}
                of {listLength} entries
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
