import axiosInstance from "../AxiosInstance";
import {
  FQA_CATEGORY_LIST,
  FQA_CATEGORY_ORDER,
  FQA_LIST,
  FQA_LIST_ORDER,
  FQA_SUBCATEGORY_LIST,
  GRAPH_DATA,
  POSTS,
  REDEEM_LIST,
  REFFERAl_LIST,
  REMOVE_IMAGES,
  SUPPORTLIST,
  UPDATE_PREMIUM,
  USERLIST,
  USER_DETAILS,
  USER_REFERRAL_AMOUNT,
  USER_REFERRAL_LIST,
  VERIFICATION_LIST,
  VERIFY_MULTIPLE,
  WITHDRAWAL_REQUESTS,
} from "./UserEndpoint";

export function getUserList(
  page,
  limit,
  search,
  gender,
  status,
  isFormFilled,
  signUpType
) {
  return axiosInstance.get(
    USERLIST +
      `?page=${page}&limit=${limit}&search=${search}&gender=${gender}&userList=${status}&formFill=${isFormFilled}&loginType=${signUpType}`
  );
}
export function getUserDetails(id) {
  return axiosInstance.get(USER_DETAILS + `?userId=${id}`);
}
export function updateImages(postData) {


  return axiosInstance.put(REMOVE_IMAGES, postData);
}
export function updatePremium(id) {
  const postData = { userId: id };
  return axiosInstance.put(UPDATE_PREMIUM, postData);
}

export function getGraphData(type) {
  return axiosInstance.get(GRAPH_DATA + `?type=${type}`);
}
export function deleteUser(id) {
  return axiosInstance.delete(USERLIST + `?userId=${id}`);
}
export function blockUser(id) {
  const postData = { userId: id };
  return axiosInstance.put(USERLIST, postData);
}

export function getVerificationRequests(page, limit, type, status,gender) {
  return axiosInstance.get(
    VERIFICATION_LIST +
      `?page=${page}&limit=${limit}&type=${type}&filter=${status}&gender=${gender}`
  );
}

export function approveMultiRequest(formData) {
  return axiosInstance.put(VERIFY_MULTIPLE, formData);
}
export function approveRejectRequest(formData) {
  return axiosInstance.put(VERIFICATION_LIST, formData);
}

/////////////===================support======================//////////////////////////////////////

export function getSupportList(page, limit, sort) {
  return axiosInstance.get(
    SUPPORTLIST + `?page=${page}&limit=${limit}&sort=${sort}`
  );
}

export function UpdateSupportStatus(formdata) {
  return axiosInstance.put(SUPPORTLIST, formdata);
}

export function deleteSupport(id) {
  return axiosInstance.put(SUPPORTLIST + `?ticketId=${id}`);
}

/////////////////////////================Refferal List========================////////////////////////

export function getRefferalList(page, limit, search, sortBy, selectedTab) {
  return axiosInstance.get(
    REFFERAl_LIST +
      `?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortBy}&createdBy=${selectedTab}`
  );
}

export function createReferralCode(formData) {
  return axiosInstance.post(REFFERAl_LIST, formData);
}

export function deleteCode(id) {
  return axiosInstance.delete(REFFERAl_LIST + `?referralId=${id}`);
}

export function getRedeemedList(page, limit, type, id) {
  return axiosInstance.get(
    REDEEM_LIST + `?page=${page}&limit=${limit}&type=${type}&referralId=${id}`
  );
}

/////////////////////////================ Faqs   ===========================////////////////////////
export function getFaqsList(page, limit, id) {
  return axiosInstance.get(FQA_LIST + `?categoryId=${id}`);
}

export function createFaq(formData) {
  return axiosInstance.post(FQA_LIST, formData);
}

export function editFaq(formData) {
  return axiosInstance.put(FQA_LIST, formData);
}

export function deleteFaq(id, faqId) {
  return axiosInstance.delete(FQA_LIST + `?questionId=${id}&faqId=${faqId}`);
}
export function changeFaqOrder(formData) {
  return axiosInstance.put(FQA_LIST_ORDER, formData);
}

export function getFaqsCategory(page, limit) {
  return axiosInstance.get(FQA_CATEGORY_LIST + `?page=${page}&limit=${limit}`);
}

export function changeFaqCategoryOrder(formData) {
  return axiosInstance.put(FQA_CATEGORY_ORDER, formData);
}
export function createCategory(formData) {
  return axiosInstance.post(FQA_CATEGORY_LIST, formData);
}
export function editCategory(formData) {
  return axiosInstance.put(FQA_CATEGORY_LIST, formData);
}
export function deleteFaqCategory(id) {
  return axiosInstance.delete(FQA_CATEGORY_LIST + `?categoryId=${id}`);
}

//////////////================subcategory============////////////////

export function getFaqsSubCategory(page, limit, categoryId) {
  return axiosInstance.get(
    FQA_SUBCATEGORY_LIST +
      `?page=${page}&limit=${limit}&categoryId=${categoryId}`
  );
}

export function createSubCategory(formData) {
  return axiosInstance.post(FQA_SUBCATEGORY_LIST, formData);
}

export function deleteFaqSubCategory(subCategoryId) {
  return axiosInstance.delete(
    FQA_SUBCATEGORY_LIST + `?subcategoryId=${subCategoryId}`
  );
}

//////////////////////======================withdrawal requests===========////////////////////////

export function getWithdrawalRequests(page, limit) {
  return axiosInstance.get(
    WITHDRAWAL_REQUESTS + `?page=${page}&limit=${limit}`
  );
}

export function withdrawalRequestAction(formData) {
  return axiosInstance.put(WITHDRAWAL_REQUESTS, formData);
}

export function getUserReferralList(page, limit, filterStatus, id) {
  return axiosInstance.get(
    USER_REFERRAL_LIST +
      `?page=${page}&limit=${limit}&type=${filterStatus}&userId=${id}`
  );
}
export function getPayNowList(id) {
  return axiosInstance.get(USER_REFERRAL_AMOUNT + `?referralId=${id}`);
}
export function payNow(formData) {
  return axiosInstance.put(USER_REFERRAL_AMOUNT, formData);
}

/////////////=============== posts===================//////////////////////

export function getPostList(page, limit) {
  return axiosInstance.get(POSTS + `?page=${page}&limit=${limit}`);
}
export function deletePost(id) {
  return axiosInstance.delete(POSTS + `?postId=${id}`);
}
