import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import VerificationRequestsTable from "./VerificationRequestsTable";
import UsersTable from "./UsersTable";
import { useLocation } from "react-router-dom";

export default function UserList() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab
      ? location?.state?.selectedTab
      : "users"
  );

  const tabData = [
    { name: "Verification Requests", component: <VerificationRequestsTable /> },
    { name: "Users", component: <UsersTable /> },
  ];

  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-3">
                {tabData.map((data, i) => (
                  <Tab.Pane
                    eventKey={data.name.toLowerCase()}
                    onClick={() => setSelectedTab(data.name.toLowerCase())}
                    key={i}
                  >
                    {data.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
