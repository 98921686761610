import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Nav, Tab, Table } from "react-bootstrap";
import {
  deleteCode,
  getRefferalList,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import moment from "moment";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import ReferralCode from "../components/Modal/ReferralCode";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import PayNow from "../components/Modal/PayNow";

export default function RefferalCodes() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [datas, setDatas] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [sortBy, setSortBy] = useState("all");
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab ? location?.state?.selectedTab : "admin"
  );
  console.log(location, "location");
  const [id, setId] = useState("");
  const limit = 10;
  const navigate = useNavigate();

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  async function getTableData() {
    setLoader(true);
    try {
      const response = await getRefferalList(
        currentPage,
        limit,
        search,
        sortBy,
        selectedTab
      );
      setDatas(response.data.data?.codes);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteCode(id);
      notifyTopRight(response.data?.message);
      getTableData();
    } catch (error) {
      notifyError(error.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, sortBy, selectedTab]);

  let timeoutId;
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (!initialFetch) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [search]);

  const tabData = [
    {
      name: "Admin",
      content: (
        <>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div className="col-6">
              <div
                className="input-group border bg-white input-group-sm"
                style={{ borderRadius: "8px" }}
              >
                <input
                  style={{
                    paddingBottom: "15px",
                    paddingTop: "15px",
                    borderRadius: "10px",
                    fontSize: "14px",
                  }}
                  type="text"
                  className="form-control float-right border-0"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-default fs-12"
                    onClick={getTableData}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary py-2 px-3"
                onClick={() => setShowModal(true)}
              >
                Add +
              </button>
            </div>
          </div>

          <div className="mb-2 col-6">
            <label className="text-black font-w600 fs-14">Sort by</label>
            <select
              className="form-control"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"most"}>Most Redeemed</option>
              <option value={"least"}>Least Redeemed</option>
            </select>
          </div>

          {loader ? (
            <Spinner />
          ) : (
            <Table
              responsive
              className="mostly-customized-scrollbar mb-0 border-top"
            >
              <thead style={{ color: "black" }}>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Created At</th>
                  <th>Expires on</th>
                  <th>Redeemed By</th>
                  <th>Total Earning</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {datas &&
                  datas.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.name || "-"}</td>
                      <td>{item.code}</td>
                      <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {item.expiresAt
                          ? moment.unix(item.expiresAt).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                      <td
                        className={
                          item.referralHits.total > 0 ? "pointer link-text" : ""
                        }
                        onClick={() =>
                          item.referralHits.total > 0 &&
                          navigate("/redeemed-by", {
                            state: {
                              id: item._id,
                              table: "table1",
                              selectedTab: "admin",
                            },
                          })
                        }
                      >
                        {item?.referralHits?.total}
                      </td>
                      <td>${item?.totalEarning}</td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                navigate("/payed-by-admin", {
                                  state: { id: item?._id },
                                })
                              }
                            >
                              View
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                setShowPayModal(true);
                                setId(item?._id);
                              }}
                            >
                              Pay Now
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => onDelete(item._id)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <div className="d-flex gap-3 align-items-center">
                          <button
                            onClick={() =>
                              navigate("/payed-by-admin", {
                                state: { id: item?._id },
                              })
                            }
                            className="btn btn-primary py-1 px-2"
                            style={{ minWidth: "74px" }}
                          >
                            View
                          </button>
                          <button
                            onClick={() => {
                              setShowPayModal(true);
                              setId(item?._id);
                            }}
                            className="btn btn-primary py-1 px-2"
                            style={{ minWidth: "74px" }}
                          >
                            Pay Now
                          </button>
                          <button
                            onClick={() => onDelete(item._id)}
                            className="btn btn-danger py-1 px-2"
                          >
                            <i class="flaticon-381-trash"></i>
                          </button>
                        </div> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}

          {datas?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5">
              Sorry, Data Not Found!
            </div>
          )}

          {datas?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      name: "General",
      content: (
        <>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div className="col-6">
              <div
                className="input-group border bg-white input-group-sm"
                style={{ borderRadius: "8px" }}
              >
                <input
                  style={{
                    paddingBottom: "15px",
                    paddingTop: "15px",
                    borderRadius: "10px",
                    fontSize: "14px",
                  }}
                  type="text"
                  className="form-control float-right border-0"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-default fs-12"
                    onClick={getTableData}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2 col-6">
            <label className="text-black font-w600 fs-14">Sort by</label>
            <select
              className="form-control"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"most"}>Most Redeemed</option>
              <option value={"least"}>Least Redeemed</option>
            </select>
          </div>

          {loader ? (
            <Spinner />
          ) : (
            <Table
              responsive
              className="mostly-customized-scrollbar mb-0 border-top"
            >
              <thead style={{ color: "black" }}>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Redeemed By</th>
                  <th>Total Earning</th>
                  <th>Pending Balance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {datas &&
                  datas.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.name}</td>
                      <td>{item?.code}</td>

                      <td
                        className={
                          item.referralHits.total > 0 ? "pointer link-text" : ""
                        }
                        onClick={() =>
                          item.referralHits.total > 0 &&
                          navigate("/redeemed-by", {
                            state: {
                              id: item._id,
                              table: "table1",
                              selectedTab: "general",
                            },
                          })
                        }
                      >
                        {item.referralHits.total}
                      </td>
                      <td>${item?.totalEarning}</td>
                      <td>${item?.pendingEarning}</td>
                      <td>
                        <button
                          onClick={() => onDelete(item._id)}
                          className="btn btn-danger py-1 px-2"
                        >
                          <i class="flaticon-381-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}

          {datas?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5">
              Sorry, Data Not Found!
            </div>
          )}

          {datas?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <PageTitle activeMenu="Referral Codes List" motherMenu="Referral Codes" />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      eventKey={data.name.toLowerCase()}
                      onClick={() => setSelectedTab(data.name.toLowerCase())}
                    >
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    {data.content}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>

      <ReferralCode
        show={showModal}
        setShow={setShowModal}
        getReferralCodes={getTableData}
      />
      {showPayModal && (
        <PayNow
          show={showPayModal}
          id={id}
          table={getTableData}
          onHide={() => setShowPayModal(false)}
        />
      )}
    </div>
  );
}
