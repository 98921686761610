import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import { approveRejectRequest, updateImages } from "../../../services/UserServices/UserService";

const ReasonForPicDelete = ({ show, id, images, table, onHide }) => {
  const [reason, setReason] = useState("");
  const [reasonCh, setReasonCh] = useState("");
  const [errors, setErrors] = useState({ reason: "" ,reason_ch:""});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          userId: id,
          images: images,
          reasonsToDeleteImages: reason,
          reasonsToDeleteImages_ch: reasonCh,
        };
        await updateImages(payload);
        notifyTopRight("Updated Successfully.");
        table();
        onHide();
        setReason("");
      } catch (error) {
        notifyError(error.response?.data?.message || "An error occurred");
        console.error("Error:", error.response || error);
      }
    }
  };
  const validateForm = () => {
    let isValid = true;
    const errorObj = { reason: "" };

    if (!reason) {
      errorObj.reason = "This field is required";
      isValid = false;
    }
    if (!reasonCh) {
        errorObj.reason_ch = "This field is required";
        isValid = false;
      }

    setErrors(errorObj);
    return isValid;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Reason</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Reason(English)</label>
            <textarea
              rows={5}
              className="form-control"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors({ ...errors, reason: "" });
              }}
            />
            {errors.reason && (
              <div className="text-danger fs-12">{errors.reason}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Reason(Chinese)</label>
            <textarea
              rows={5}
              className="form-control"
              value={reasonCh}
              onChange={(e) => {
                setReasonCh(e.target.value);
                setErrors({ ...errors, reason_ch: "" });
              }}
            />
            {errors.reason_ch && (
              <div className="text-danger fs-12">{errors.reason_ch}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReasonForPicDelete;
