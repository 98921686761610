import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import { postNotification } from "../../services/PushNotification/NotificationService";
import ButtonLoader from "../components/Common/ButtonLoader/ButtonLoader";
import addPhoto from "../../services/Auth/AuthService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@tinymce/tinymce-react";

export default function Notification() {
  let errorsObj = {
    title: "",
    title_ch: "",
    message: "",
    message_ch: "",
    type: "",
  };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    title: "",
    title_ch: "",
    message: "",
    message_ch: "",
    type: "all",
  });

  const [loader, setLoader] = useState(false);
  const handleChangeText = (content) => {
    setFormData({ ...formData, message:content  });
    setErrors({ ...errors, message: "" });
  };
  const handleChangeTextCh = (content) => {
    setFormData({ ...formData, message_ch:content  });
    setErrors({ ...errors, message_ch: "" });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.title.trim() === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (formData?.title_ch.trim() === "") {
      errorObj.title_ch = "Title is Required";
      error = true;
    }
    if (formData?.message.trim() === "") {
      errorObj.message = "Body is Required";
      error = true;
    }
    if (formData?.message_ch.trim() === "") {
      errorObj.message_ch = "Body is Required";
      error = true;
    }
    setErrors(errorObj);

    if (error) {
      return;
    }

    try {
      const response = await postNotification(formData);
      notifyTopRight("Sent Successfully.");
      setFormData({
        title: "",
        message: "",
        title_ch: "",
        message_ch: "",
        type: "all",
        image: null,
      });
      // Reset the form
      if (formRef.current) {
        formRef.current.reset();
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError("Something went wrong please try again!");
    }
  };
  const albumName = "notificationImage";

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      const selectedFile = e.target.files[0]; // Get the first selected file

      if (selectedFile) {
        addPhoto(selectedFile, albumName)
          .then((response) => {
            console.log(response, "image response"); // Log the response
            const imageName = response?.key;

            if (imageName) {
              setFormData({
                ...formData,
                image: imageName,
              });
            }
          })
          .catch((error) => {
            console.log(error, "image upload error");
          });
      }
    }
  };

  return (
    <div>
      <PageTitle activeMenu="Send Notification" motherMenu="Notification" />

      <div className="">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit} ref={formRef}>
            <div className="form-group mb-3">
              <label className="text-black font-w500">Image</label>
              <div className="contact-name">
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  name="image"
                  onChange={(e) => handleInputChange(e)}
                />
                <span className="validation-text"></span>
                {errors.image && (
                  <div className="text-danger fs-12">{errors.image}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title(English)</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={formData.title}
                onChange={handleInput}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title(Chinese)</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="title_ch"
                value={formData.title_ch}
                onChange={handleInput}
                placeholder="Enter title"
              />
              {errors.title_ch && (
                <div className="text-danger fs-12">{errors.title_ch}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body(English)</strong>
              </label>
              <Editor
                apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image code charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code textcolor wordcount",
                    "textarea",
                    "textcolor",
                    "forecolor backcolor",
                  ],
                  toolbar:
                    "undo redo | formatselect | code |link | image | bold italic backcolor underline | alignleft aligncenter alignright alignjustify |  \n" +
                    "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                  content_style: "body { color: #000 }",
                }}
                onEditorChange={handleChangeText}
                name="prescription"
                value={formData.message}
              />
              {errors.message && (
                <div className="text-danger fs-12">{errors.message}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body(Chinese)</strong>
              </label>
              <Editor
                apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image code charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code textcolor wordcount",
                    "textarea",
                    "textcolor",
                    "forecolor backcolor",
                  ],
                  toolbar:
                    "undo redo | formatselect | code |link | image | bold italic backcolor underline | alignleft aligncenter alignright alignjustify |  \n" +
                    "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                  content_style: "body { color: #000 }",
                }}
                onEditorChange={handleChangeTextCh}
                name="prescription"
                value={formData.message}
              />
           
            
              {errors.message_ch && (
                <div className="text-danger fs-12">{errors.message_ch}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Send To</strong>
              </label>
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleInput}
              >
                <option value={"all"}>All</option>
                <option value={"male"}>Males</option>
                <option value={"female"}>Females</option>
              </select>

              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <ButtonLoader /> Send
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
