import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import { getPayNowList } from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import moment from "moment";

import PageTitle from "../layouts/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";

export default function PayedByAdmin() {
  const location = useLocation();
  const id = location?.state?.id;

  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("all");
  const [filterStatus, setFilterStatus] = useState("accepted");
  const navigate = useNavigate();
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  async function getTableData() {
    setLoader(true);
    try {
      const response = await getPayNowList(id);
      console.log(response.data, "12365");
      setUsers(response.data.data);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <div>
      <PageTitle activeMenu="Payed by Admin" motherMenu="Payed by Admin" />
      <Card>
        <Card.Body className="pb-0">
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Amount</strong>
                  </th>
                  <th>
                    <strong>Date</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>${item?.amount}</td>
                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5">
              Sorry, Data Not Found!
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
