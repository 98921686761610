import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Tab,
  Nav,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  UpdateSupportStatus,
  deleteSupport,
  getSupportList,
} from "../../services/UserServices/UserService";
import Pagination from "../components/Common/Pagination";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import moment from "moment";

export default function SupportRequests(props) {
  const [loader, setLoader] = useState(false);
  const [sort, setSort] = useState("");
  const [datas, setDatas] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  //   const imgUrl = "https://closertech.s3.us-west-2.amazonaws.com/";
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  async function getTableData() {
    setLoader(true);
    try {
      const response = await getSupportList(currentPage, limit, search, sort);
      console.log(response.data, "12365");
      setDatas(response.data.data?.list);
      setLoader(false);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data.data?.count);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  }

  async function onDelete(id) {
    setLoader(true);
    try {
      const response = await deleteSupport(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "helooo");
      setLoader(false);
    }
  }

  async function onAction(id, status) {
    setLoader(true);
    try {
      const formData = { ticketId: id, status };
      const response = await UpdateSupportStatus(formData);
      notifyTopRight(response.data?.message);
      getTableData();
      console.log(response);
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "helooooooooo");
      setLoader(false);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, sort]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  const tabData = [
    {
      name: "New",
      //   icon: "home",
      content: "",
    },
    {
      name: "Ongoing",
      //   icon: "user",
      content: "",
    },
    {
      name: "Past",
      //   icon: "phone",
      content: "",
    },
  ];
  return (
    <div>
      <PageTitle activeMenu="Support List" motherMenu="Support Requests" />
      <Card>
        <Card.Body>
          {/* <!-- Nav tabs --> */}
          <div className="default-tab">
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      eventKey={data.name.toLowerCase()}
                      onClick={() => setSort(data.name.toLowerCase())}
                    >
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-6">
                        <div
                          className="input-group border bg-white input-group-sm"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            style={{
                              paddingBottom: "25px",
                              paddingTop: "25px",
                              borderRadius: "10px",
                              fontSize: "14px",
                            }}
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {loader ? (
                      <Spinner />
                    ) : (
                      <Table responsive className="mostly-customized-scrollba">
                        <thead style={{ color: "black" }}>
                          <tr>
                            <th>
                              <strong>ticket no.</strong>
                            </th>
                            <th>
                              <strong>NAME</strong>
                            </th>
                            <th>
                              <strong>EMAIL</strong>
                            </th>
                            <th>
                              <strong>Category</strong>
                            </th>
                            <th>
                              <strong>type</strong>
                            </th>
                            <th>
                              <strong>message</strong>
                            </th>
                            <th>
                              <strong>date</strong>
                            </th>
                            <th>
                              <strong>STATUS</strong>
                            </th>
                            <th>
                              <strong>ACTION</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {datas &&
                            datas?.map((item, i) => (
                              <tr key={i}>
                                <td>{item.ticket}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.category}</td>
                                <td>{item.type}</td>
                                <td style={{minWidth:"300px"}}>{item.message}</td>
                                <td>
                                  {moment(item?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <Badge bg="" className="badge-success">
                                    {item?.status}
                                  </Badge>
                                </td>
                                <td>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="info light"
                                      className="light sharp btn btn-info i-false"
                                    >
                                      {svg1}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {item?.status === "close" ? (
                                        ""
                                      ) : item?.status === "inProgress" ? (
                                        <Dropdown.Item
                                          onClick={() =>
                                            onAction(item._id, "resolved")
                                          }
                                        >
                                          Resolved
                                        </Dropdown.Item>
                                      ) : (
                                        <>
                                          <Dropdown.Item
                                            onClick={() =>
                                              onAction(item._id, "inProgress")
                                            }
                                          >
                                            Inprogress
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            onClick={() =>
                                              onAction(item._id, "resolved")
                                            }
                                          >
                                            Resolved
                                          </Dropdown.Item>
                                        </>
                                      )}

                                      <Dropdown.Item
                                        onClick={() => onDelete(item._id)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                    {datas?.length === 0 && !loader ? (
                      <div className="justify-content-center d-flex my-5 ">
                        Sorry, Data Not Found!
                      </div>
                    ) : (
                      ""
                    )}
                    {datas?.length !== 0 && (
                      <div className="card-footer clearfix">
                        <div className="d-block d-sm-flex justify-content-between align-items-center">
                          <div className="dataTables_info">
                            Showing {currentPage * limit + 1} to{" "}
                            {listLength > (currentPage + 1) * limit
                              ? (currentPage + 1) * limit
                              : listLength}{" "}
                            of {listLength} entries
                          </div>
                          <Pagination
                            pageCount={pageCount}
                            pageValue={currentPage}
                            setPage={setCurrentPage}
                          />
                        </div>
                      </div>
                    )}

                   
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
