import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Accordion, Card, Col } from "react-bootstrap";
import Spinner from "../components/Common/Spinner";
import {
  changeFaqCategoryOrder,
  deleteFaqCategory,
  getFaqsCategory,
} from "../../services/UserServices/UserService";
import AddFaqCategory from "../components/Modal/AddFaqCategory";
import arrowIcon from "../../images/svg/iconamoon_arrow-left-2-bold.svg";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Common/Pagination";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import EditCategory from "../components/Modal/EditCategory";
import DraggableList from "react-draggable-list";

export default function FAQs() {
  const [isDeleteHovered, setDeleteHovered] = useState("");
  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const limit = 10;
  const [listLength, setListlenght] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [oneData, setOneData] = useState(null);
  const [orderChanged, setOrderChanged] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef();

  ///function to fetch table data ///
  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getFaqsCategory(currentPage, limit);
      console.log(response, "success");
      setData(response?.data?.data.list);
      setLoader(false);
      const total = response.data?.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data?.data?.count);
    } catch (error) {
      setLoader(false);
    }
  };

  ///function to delete data ///
  const handleDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteFaqCategory(id);
      handleFetch();
      notifyTopRight("Deleted Successfully!");
      setLoader(false);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
      setLoader(false);
    }
  };
  ///function to edit data ///
  const handleEdit = async (data) => {
    setOneData(data);
    setShowEditModal(true);
  };
  const _onListChange = async (newList) => {
    setData(newList);
    setOrderChanged(true);
  };
  const onSaveOrder = async () => {
    setLoader(true);
    var raw = {
      faqs: [
        ...data.map((item, i) => {
          return {
            categoryId: item._id,
            order: i + 1,
          };
        }),
      ],
    };

    console.log(raw, "raw");
    try {
      const response = await changeFaqCategoryOrder(raw);
      handleFetch();
      notifyTopRight("Updated Successfully!");
    } catch (error) {
      console.error("Error fetching FAQs:", error);
      setLoader(false);
    }
  };

  const Item = ({ item, dragHandleProps }) => {
    return (
      <div
        {...dragHandleProps}
        style={{ border: "1px solid", zIndex: "1" }}
        className="category-card "
        onClick={() =>
          navigate("/faqs-list", {
            state: { id: item?._id, title: item?.category },
          })
        }
      >
        <p className="fs-14 text-black fw-bold">{item?.category}</p>
        <div>
          <i
            className="flaticon-381-edit-1 mx-2 text-black"
            onClick={(e) => {
              e.stopPropagation(); // Prevent accordion toggle
              handleEdit(item);
            }}
          ></i>
          <i
            className="flaticon-381-trash-1 mx-2 pe-3 text-black"
            onClick={(e) => {
              e.stopPropagation(); // Prevent accordion toggle
              handleDelete(item?._id);
            }}
          ></i>
          <img src={arrowIcon} width={26} />
        </div>
      </div>
    );
  };
  useEffect(() => {
    handleFetch();
  }, [currentPage]);
  return (
    <div>
      <PageTitle activeMenu="FAQs Category" motherMenu="FAQs" />
      <Col>
        <Card>
          <Card.Header className="d-flex">
            <Card.Title>FAQs Category</Card.Title>
            <div>

           
            {orderChanged && (
                <button
                  className="btn btn-secondary me-2 py-2"
                  onClick={() => {
                    onSaveOrder();
                  }}
                >
                  Save Change Order
                </button>
              )}
            <button
              type="button"
              className="btn btn-primary py-2"
              onClick={() => setShowAddModal(true)}
            >
              Add +
            </button>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              // data?.map((item, i) => (
                // <div
                //   key={i}
                //   className="category-card "
                //   onClick={() =>
                //     navigate("/faqs-list", {
                //       state: { id: item?._id, title: item?.category },
                //     })
                //   }
                // >
                //   <p className="fs-14 text-black fw-bold">{item?.category}</p>
                //   <div>
                //     <i
                //       className="flaticon-381-edit-1 mx-2 text-black"
                //       onClick={(e) => {
                //         e.stopPropagation(); // Prevent accordion toggle
                //         handleEdit(item);
                //       }}
                //     ></i>
                //     <i
                //       className="flaticon-381-trash-1 mx-2 pe-3 text-black"
                //       onClick={(e) => {
                //         e.stopPropagation(); // Prevent accordion toggle
                //         handleDelete(item?._id);
                //       }}
                //     ></i>
                //     <img src={arrowIcon} width={26} />
                //   </div>
                // </div>
                <div ref={containerRef} className="draggable-list-container">
                  <DraggableList
                    itemKey="_id"
                    template={Item}
                    list={data}
                    onMoveEnd={(newList) => _onListChange(newList)}
                    container={() => containerRef.current}
                    className="custom-draggable-list"
                  />
                </div>
              // ))
            )}
            {data?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {showAddModal && (
        <AddFaqCategory
          show={showAddModal}
          table={handleFetch}
          onHide={() => setShowAddModal(false)}
        />
      )}
      {showEditModal && (
        <EditCategory
          show={showEditModal}
          data={oneData}
          table={handleFetch}
          onHide={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
}
